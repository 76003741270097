import $ from 'jquery';

//スムーススクロール
$('a[href^="#"]').click(function(){
    const speed = 500;
    const href= $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
});

$('.sp_menu').on('click', function(){
    $('.sp_nav').addClass('nav_open');
})

$('.sp_nav_close').on('click', function(){
    $('.sp_nav').removeClass('nav_open');
})